import DceElementExtention from "./DceElementExtention";
import DceComponentsRegister from "./DceComponentsRegister";
import DceHTMLElement from "../types/DceHTMLElement";
import DceEngineEvents from "./DceEngineEvents";
import {Logger} from "dce-logger";
import {Event} from "jquery";
import DcePluginRegister from "../plugins/DcePluginRegister";

export default class DceEngine {

    private static logger = Logger.of("dce-engine");

    private static instance: DceEngine;

    private dceAttr: string = "dce";
    private dceSelector: string = "[dce]";

    private dceIgnoreSelector: string = "[data-dce-ignore]";

    private register: DceComponentsRegister;
    private plugins: DcePluginRegister;

    private body: HTMLElement = document.body;

    private observer: MutationObserver;

    private componentNodes: DceHTMLElement[];

    /**
     * Elementy na których ustawione jest obesrowanie na inicjowanie komponentów
     * @private
     */
    private watchedElements: HTMLElement[];

    /**
     * Elementy w ramach których ignorujemy zmiany
     * @private
     */
    private ignoredElements: HTMLElement[];

    private constructor() {
        this.register = DceComponentsRegister.getInstance();
        this.plugins = DcePluginRegister.getInstance();

        this.componentNodes = [];
        this.watchedElements = [];
        this.ignoredElements = [];
    }

    static getInstance(): DceEngine {
        DceEngine.logger.log("DCE Get Instance");

        DceEngine.logger.log("Window", window);
        if (!DceEngine.instance) {
            DceEngine.instance = new DceEngine();
        }

        return DceEngine.instance;
    }


    public findSubComponents(parent: HTMLElement): DceHTMLElement[] {
        return this.componentNodes.filter(a => {
            return parent.contains(a);
        });
    }


    private init(attr: string = 'dce') {

        this.dceAttr = attr;
        this.dceSelector = '[' + attr + ']';

        this.initializeBody();

        this.observer = new MutationObserver((mutations) => {

            console.log("MUTATION OBSERVER: ", mutations);

            mutations.forEach((mutation) => {

                mutation.removedNodes.forEach((n) => {

                    if (!Object.prototype.hasOwnProperty.call(n, "firebugIgnore")) {
                        //usuwanie z watched
                        if (n.nodeType == Node.ELEMENT_NODE) {
                            let remElement = n as HTMLElement;
                            this.watchedElements = this.watchedElements.filter(e => {
                                return !(remElement == e || remElement.contains(e));
                            });
                        }

                        let components = this.findComponents(n);
                        this.removeComponents(components);
                    }

                });

                mutation.addedNodes.forEach((n) => {

                    //pre ignoring
                    for (let i = 0; i < this.ignoredElements.length; i++) {
                        if (this.ignoredElements[i].contains(n)) {
                            return;
                        }
                    }

                    if (!Object.prototype.hasOwnProperty.call(n, "firebugIgnore")) {
                        let components = this.findComponents(n);
                        this.initComponents(components);
                    }

                });


                if (mutation.addedNodes.length > 0) {

                    this.plugins.addedNodes(
                        Array.from(mutation.addedNodes)
                    );
                }


                //usuwamy ignorowane elementy z dom'a
                this.ignoredElements = this.ignoredElements.filter(e => {
                    return this.body.contains(e);
                });


            });

        });

        this.observer.observe(this.body, {
            attributes: false,
            childList: true,
            subtree: true,
        });

    }


    public watchElement(elem: HTMLElement) {
        this.watchedElements.push(elem);
    }


    private initComponents(nodes: Node[]) {
        nodes.forEach((n) => {
            if (n instanceof HTMLElement) {
                this.initializeComponentsOnElement(n as DceHTMLElement);
            }
        });
    }


    private findComponents(node: Node): Array<DceHTMLElement> {

        for (let i = 0; i < this.ignoredElements.length; i++) {
            if (this.ignoredElements[i].contains(node)) {
                //console.log("IGNORING NODE ", node);
                return [];
            }
        }

        if (node.nodeType == Node.ELEMENT_NODE) {
            (node as HTMLElement).querySelectorAll(this.dceIgnoreSelector).forEach(e => {
                this.ignoredElements.push(e as HTMLElement);
            });
        }

        var componentsArray = new Array<DceHTMLElement>();

        //DceEngine.logger.debug("FindComponents in node ", node, node.nodeType);

        if (node.nodeType == Node.ELEMENT_NODE) {

            if ((<DceHTMLElement>node).ext !== undefined) {
                componentsArray.push(node as DceHTMLElement);
            }

            if ((node as HTMLElement).hasAttribute(this.dceAttr)) {
                componentsArray.push(node as DceHTMLElement);
            }

            var components = (node as HTMLElement).querySelectorAll(this.dceSelector);

            if (components.length > 0) {
                components.forEach((v) => {
                    componentsArray.push(v as DceHTMLElement);
                })
            }
        }

        if (componentsArray.length > 0) {
            console.log("components found:", componentsArray);
        }

        return componentsArray;
    }

    private initializeBody() {
        var elements: NodeList = this.body.querySelectorAll(this.dceSelector);
        if (elements) {
            this.initComponents(Array.prototype.slice.call(elements));
        }

        let ignored: NodeList = this.body.querySelectorAll(this.dceIgnoreSelector);
        this.ignoredElements = Array.prototype.slice.call(ignored);

        this.plugins.initializeBody(this.body);

        //this.bindEvents([this.body]);
    }

    private removeComponents(nodes: Node[]) {
        nodes.forEach((n) => {

            if (n instanceof HTMLElement) {

                let dce = n as DceHTMLElement;

                if (dce.ext) {

                    let parentComponents = this.componentNodes.filter(c => {
                        return c.contains(dce);
                    });

                    let comps = dce.ext.getInitializedComponents();
                    comps.forEach(c => {
                        n.dispatchEvent(DceEngineEvents.removeEvent(c, dce));

                        parentComponents.forEach(p => {
                            p.dispatchEvent(DceEngineEvents.subComponentRemove(c, dce));
                        })
                    });

                }

                var index = this.componentNodes.indexOf(dce);
                if (index > -1) {
                    this.componentNodes.splice(index, 1);
                }

            }
        })
    }

    public addComponentsOnElement(elem: DceHTMLElement, name: string, props: any): any {

        let attr = name + ":" + JSON.stringify(props);

        if (elem.hasAttribute(this.dceAttr)) {
            let oldDceAttr = elem.getAttribute(this.dceAttr);

            attr = oldDceAttr
                + (oldDceAttr.endsWith(",") ? "" : ",")
                + attr;
        }

        elem.setAttribute(this.dceAttr, attr);
        this.initializeComponentsOnElement(elem)

        return elem.ext.getComponent(name);
    }

    public initializeComponentsOnElement(elem: DceHTMLElement) {
        let dceAttr = elem.getAttribute(this.dceAttr);

        if (null == dceAttr) {
            return;
        }

        if (elem.ext === undefined) {
            elem.ext = new DceElementExtention(elem);
            this.plugins.mountedElementExtension(elem);
        }

        let dce = this.parseDceAttr(dceAttr);

        let parentElem = elem;
        do {

            parentElem = parentElem.parentElement as DceHTMLElement;

            if (parentElem == null) {
                break;
            }

            if (parentElem.ext !== undefined) {

                if (parentElem.ext.getInitializedComponents().length > 0) {
                    elem.parentDceElement = parentElem;
                    break;
                }

            }

        } while (parentElem.tagName != 'body')


        let parentComponents: HTMLElement[] = this.componentNodes.filter(c => {
            return c.contains(elem);
        });

        parentComponents.push(
            ...(this.watchedElements.filter(c => {
                return c.contains(elem)
            }))
        );

        parentComponents = [...new Set(parentComponents)];

        let dispatchInitEvents = [];

        for (var name in dce) {

            try {
                if (!dce.hasOwnProperty(name) || elem.ext.hasComponent(name)) {
                    continue;
                }

                if (!this.register.contains(name)) {
                    throw new Error("Unknown component: " + name + " defined on element " + elem);
                }

                DceEngine.logger.debug("Init", name, elem);

                let constr = this.register.getConstructor(name);

                let component = new constr(elem, dce[name]);
                elem.ext.addComponent(name, component);

                dispatchInitEvents.push(DceEngineEvents.initEvent(name, elem));

                parentComponents.forEach(p => {
                    p.dispatchEvent(DceEngineEvents.subComponentInit(name, elem))
                });

            } catch (e) {
                console.warn(e);
            }

        }

        for (const dispatchInitEvent of dispatchInitEvents) {
            DceEngine.logger.debug("Calling subcomp " + name + " init on:", elem);
            elem.dispatchEvent(dispatchInitEvent);
        }



        if (this.componentNodes.indexOf(elem) === -1) {
            this.componentNodes.push(elem);
        }

    }

    private parseDceAttr(attr: String) {
        if (!attr.startsWith("{")) {
            attr = "{" + attr + "}";
        }
        return this.saferEval(attr);
    }

    /**
     * stolen from alpine js
     * @param expression js object
     */
    private saferEval(expression: String) {
        var result = new Function(
            "$data",
            `var __dce_result; with($data) { __dce_result = ${expression} }; return __dce_result`
        )({});

        return result;
    }

    public getIgnoredElements(): HTMLElement[] {
        return this.ignoredElements;
    }


    private lazyInit(attr: string = 'dce') {

        if ('requestIdleCallback' in window) {
            // Use requestIdleCallback to schedule work.
            requestIdleCallback(() => {
                this.init(attr);
            }, {
                timeout: 200
            });
        } else {
            setTimeout(() => {
                this.init(attr);
            }, 100)
        }

    }

    /**
     * Start engine'u
     * @param attr - atrybut na elemencie wskazujacy komponenty, domyślnie dce, ale może być data-dce lub cokolwiek
     * @param lazy - ładowanie w requestIdleCallback czy nie
     */
    static start(attr: string = "dce", lazy: boolean = false) {
        let done = false;
        let doc = window.document;
        let init = function (e: Event) {
            if (e.type == "readystatechange" && doc.readyState != "complete") {
                return;
            }

            let t: EventTarget = e.type == "load" ? window : doc;
            t.removeEventListener(e.type, init, false);

            if (!done && (done = true)) {
                if (lazy) {
                    DceEngine.getInstance().lazyInit(attr);
                } else {
                    DceEngine.getInstance().init(attr);
                }
            }
        };

        if (doc.readyState == "complete") {
            //start
            if (lazy) {
                DceEngine.getInstance().lazyInit(attr);
            } else {
                DceEngine.getInstance().init(attr);
            }
        } else {
            doc.addEventListener("DOMContentLoaded", init, false);
            doc.addEventListener("readystatechange", init, false);
            window.addEventListener("load", init, false);
        }
    }
}
