import Axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import Logger from "dce-logger/lib/logger/Logger"
import DceAjaxResponse from "./DceAjaxResponse";
import {DceHttpStatus} from "./DceHttpStatus";
import DceAjaxAction from "./DceAjaxAction";
import DceAjaxRedirectAction from "./actions/DceAjaxRedirectAction";
import DceAjaxCache from "./DceAjaxCache";
import DceAjaxNewTabAction from "./actions/DceAjaxNewTabAction";

const apiConfig = {
    returnRejectedPromiseOnError: true,
    withCredentials: true,
    timeout: 30000,
    headers: {
        common: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "Pragma": "no-cache",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        },
    },
}

export default class DceAjax {

    private static instance: DceAjax;

    private api: AxiosInstance;

    private cache: DceAjaxCache;

    protected logger = Logger.of("DceAjax");

    protected actions: Map<string, DceAjaxAction[]> = new Map<string, DceAjaxAction[]>();

    static getInstance(config?: AxiosRequestConfig): DceAjax {
        if (!DceAjax.instance) {
            DceAjax.instance = new DceAjax(config);
            DceAjax.instance.registerActionHandler("redirect", new DceAjaxRedirectAction());
            DceAjax.instance.registerActionHandler("openTab", new DceAjaxNewTabAction());
        }

        return DceAjax.instance;
    }


    public static cancelTokenSource(): CancelTokenSource {
        return Axios.CancelToken.source();
    }

    /**
     * Creates an instance of DceAjax.
     *
     * @param {import("axios").AxiosRequestConfig} [config] - axios configuration.
     * @memberof DceAjax
     */
    private constructor(config?: AxiosRequestConfig) {
        this.logger = Logger.of("AJAX");
        this.cache = new DceAjaxCache();

        let meta = document.querySelector('meta[name="csrf-token"]');

        if (meta) {
            let token = meta.getAttribute('content');
            // @ts-ignore
            apiConfig.headers.common['Csrf-Token'] = token;
        }

        this.api = Axios.create({
            ...apiConfig,
            ...config
        });

        this.api.interceptors.response.use(
            (response: AxiosResponse) => {
                console.log("interceptor");
                return response;
                //return new DceAjaxResponse(response);
            },
            (error: any) => {

                let resp = error.response as AxiosResponse;
                console.log("INTERCEPTED RESP", resp)

                if (resp == undefined) {
                    return false;
                }

                if (DceHttpStatus.UNAUTHORIZED.valueOf() === resp.status
                ) {
                    window.location.replace('/');
                    return false;
                } else if (DceHttpStatus.INTERNAL_SERVER_ERROR === resp.status) {

                    console.log(typeof resp.data);

                    if (typeof resp.data === 'string') {
                        document.body.insertAdjacentHTML('beforeend', resp.data);
                    } else {
                        alert("Błąd systemu!");
                    }

                    return Promise.reject(error);

                } else if (DceHttpStatus.UNPROCESSABLE_ENTITY.valueOf() === resp.status) {

                    console.log("UNPROC");

                    return DceAjaxResponse.fromAxiosResponse(resp);

                } else if (DceHttpStatus.FORBIDDEN.valueOf() === resp.status) {
                    console.log(typeof resp.data);

                    if (typeof resp.data === 'string') {
                        document.body.insertAdjacentHTML('beforeend', resp.data);
                    } else {
                        alert("Dostęp zalbokowany!");
                    }
                } else {
                    return Promise.reject(error);
                }

            }
        )

        /*
        this.api.interceptors.request.use((param: AxiosRequestConfig) => ({
            ...apiConfig,
            ...param
        } as AxiosRequestConfig));
        */

    }

    public registerActionHandler(action: string, handler: DceAjaxAction) {
        if (!this.actions.has(action)) {
            this.actions.set(action, []);
        }

        this.actions.get(action).push(handler);
    }

    public request(config: AxiosRequestConfig): Promise<DceAjaxResponse> {

        return this.api
            .request(config)
            .then(resp => {

                if (resp == undefined || (resp as any) == false) {
                    return Promise.reject();
                }

                let wrapped: DceAjaxResponse;

                if (resp instanceof DceAjaxResponse) {
                    wrapped = resp as DceAjaxResponse;
                } else {
                    wrapped = DceAjaxResponse.fromAxiosResponse(resp);
                }

                console.log("THEN IN REQUEST", wrapped.getActions());

                wrapped.getActions().forEach((v, k) => {
                    let actions: DceAjaxAction[] = [];

                    if (this.actions.has(k)) {
                        this.actions.get(k).forEach(callback => {
                            actions.push(callback);
                        });
                    }

                    actions
                        .sort((a, b) => {
                            return a.getPriority() - b.getPriority();
                        })
                        .forEach(callback => {
                            callback.execute(v);
                        });

                });

                return wrapped;
            });
    }


    public get(url: string, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {

        let conf: AxiosRequestConfig = {
            ...config,
            'url': url,
            'method': 'get'
        };

        return this.request(conf);

    }

    public getWithCache(url: string, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {
        return this.cache.get(url, config);
    }

    public delete(url: string, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {

        let conf: AxiosRequestConfig = {
            ...config,
            'url': url,
            'method': 'delete'
        };

        return this.request(conf);
    }


    public head(url: string, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {

        let conf: AxiosRequestConfig = {
            ...config,
            'url': url,
            'method': 'head'
        };

        return this.request(conf);
    }


    public post(url: string, data?: any, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {

        let conf: AxiosRequestConfig = {
            ...config,
            'url': url,
            'method': 'post',
            'data': data
        };

        return this.request(conf);
    }


    public put(url: string, data?: any, config?: AxiosRequestConfig): Promise<DceAjaxResponse> {

        let conf: AxiosRequestConfig = {
            ...config,
            'url': url,
            'method': 'put',
            'data': data
        };

        return this.request(conf);
    }


}