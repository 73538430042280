import Dce from "dce-engine/lib/decorators/Dce";
import DceComponent from "dce-engine/lib/engine/DceComponent";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import SplideLoader from "../internal/SplideLoader";
import type {Splide, Options} from "@splidejs/splide";

interface SplideProps {
    options: Options
}

@Dce("Splide")
export default class SplideBlock extends DceComponent<SplideProps> {

    private splide: Splide;

    constructor(elem: DceHTMLElement, props: SplideProps) {
        super(elem, props);

        SplideLoader.load().then(value => {

            console.log(value.Splide);



            this.splide = new value.Splide(this.elem, this.props.options).mount();
        });

    }

    defaultProps(): Partial<SplideProps> {
        return {}
    }
}
